@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
body, input{
  font-family: 'Lilita One', sans-serif;
}
#root{
  width:100%;
  max-width:1600px;
  margin: 0 auto;
  margin-bottom:120px;
}

#logo{ 
  width:90%;
  max-width:1600px;
  margin: 100px auto;
  text-align:center;
  z-index:1;
  mix-blend-mode: soft-light;
}


#logo img{
  max-width:100%; 
  display:block !important; 
}

.bottomlink{
  color:white;
  text-decoration:none;
}
.bottomlink:hover{
  color:white;
  text-decoration:underline;
}
.NavBar{
  width:100%;
  max-width:1600px;
  margin: 0px auto;
  text-align:right;
  padding-top:40px;
  color: black;
  height:50px;
  z-index:99;
  position:absolute;
  top:0;
  mix-blend-mode: soft-light !important;
}
.NavBar button, .NavBar .connectedDiv{
  mix-blend-mode: soft-light;
  font-size:2em;
  margin-right:80px;
  font-family: 'Lilita One', sans-serif;

}
.connectedDiv{
  mix-blend-mode: soft-light !important;
  font-family: 'Lilita One', sans-serif;
}

.connectedbutton{
  border:none !important;

}

.connectButton{
  padding:15px;
  border:1px solid white;
  border: 1px solid grey;
  cursor:pointer;
  border-radius:25px;
  z-index:88;
}

#frokImg{
  max-width:45%;
  margin-top:-130px;
  margin-left:50px;
  z-index:8;
  float:left;
  position:relative;
}

#imgRow{
  width:100%;
  max-width:1600px;
  margin: 0px auto;
}

.mintbox{
float:left;
margin-top:30px;
width:50%;
z-index:99;
font-size:3em;
mix-blend-mode: soft-light;
}

#minthdr{
  max-width:90%;
}

.mintQTY,.mintQTY:focus {
  font-size: 1.5em;
  font-weight:bold;
  width:85px;
  padding-left:10px;
  border:none;
  background:none;
  outline: none;
  padding-right:8px;
  float:left;
  margin-left:10px;
  background:white;
}

.mintQTY:hover{
background:white;
}

w3m-button{
  background: black;
    border-radius: 25px;
    right: 100px;
    position: relative;
    float: right;
    mix-blend-mode: hard-light !important;
    
}


.mintbtn{
  font-size:.6em;
  font-family: 'Lilita One', sans-serif;
  width: 120px;
  height: 50px;
  border-radius: 15px;
  margin-top:20px;
  margin-left:-10px;
  cursor:pointer;
  position:relative;
  border: 2px solid black;
    
}

.mintbtn:hover{

  box-shadow:
    inset 0 0 40px #fff,      /* inner white */
    inset 20px 0 80px rgb(41, 41, 41),   /* inner left magenta short */
    inset -20px 0 80px #0ff,  /* inner right cyan short */
    inset 20px 0 80px rgb(49, 49, 49),  /* inner left magenta broad */
    inset -20px 0 300px #0ff, /* inner right cyan broad */
    0 0 50px #ffffff,            /* outer white */
    -30px 0 40px rgb(197, 197, 197),        /* outer left magenta */
    40px 0 40px #0ff;         /* outer right cyan */
    cursor:pointer;
    position:relative;
    border: 2px solid black;
    color:white;
}

.mintholder{
  margin-top:0px;
  margin-left:310px;
  margin-bottom:0px;
}

.qtyholder{
  margin-top:30px;
}

.maxtext{
  font-size:.35em;
  margin-top:-10px;
  margin-left:2px;
  font-weight:300;
}

.minttext{
  float:left;
  margin-right:10px;
}

.remainingtext{clear:both; margin-top:50px;font-size:.8em;}

.paratxt{
  font-size:.7em;
  max-width:700px;
}

.modalTitle{
  font-size:1.4em;
}

.txlink{
  font-size:.8em;
}
/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  z-index:98;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: rgba(255, 255, 255, 0.79);
  margin: 10% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  max-width:500px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop; /* Animation */
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  z-index:99;
  border-radius: 25px;
  

  
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

/* The Close Button */
.close {
  color: black;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #535353;
  text-decoration: none;
  cursor: pointer;
}



.pulsefailed{
  width: 29.6px;
  height: 29.6px;
  border-radius: 50%;
  background: #a00707;
  float:right;
  margin-top:-20px;
  margin-right:-8px;
  font-size:1em;
  display:none;
}

.pulsefailedinside{
  color: transparent;  
  text-shadow: 0 0 0 white;
  margin-top:4px;
  margin-left:4px;
}


.pulseconfirmed{
  width: 29.6px;
  height: 29.6px;
  border-radius: 50%;
  background: #008211;
  float:right;
  margin-top:-20px;
  margin-right:-8px;
  font-size:1em;
  display:none;
}

.pulseconfirmedinside{
  color: transparent;  
  text-shadow: 0 0 0 white;
  margin-top:4px;
  margin-left:4px;
}

.pulse {
   width: 9.6px;
   height: 9.6px;
   border-radius: 50%;
   background: #008211;
   box-shadow: 0 0 0 0 rgba(0,130,17,0.5);
   animation: pulse-kefe91mn 1.7999999999999998s infinite linear;
   position: relative;
   float:right;
   margin-top:-10px;
   margin-right:2px;
}

.pulse:before,
.pulse:after {
   content: "";
   position: absolute;
   inset: 0;
   border-radius: inherit;
   box-shadow: 0 0 0 0 rgba(0,130,17,0.5);
   animation: inherit;
   animation-delay: -0.6s;
}

.pulse:after {
   animation-delay: -1.2s;
}

@keyframes pulse-kefe91mn {
   100% {
      box-shadow: 0 0 0 19.2px #0000;
   }
}


.infoRow{clear:both;min-width:100%;margin-bottom:50px;text-align:center;}
.infoCol{
  float:left;
  width:33%;
  text-align:center;
  font-size:1.5em;
  mix-blend-mode: soft-light;
  line-height:35px;
}

.roadmaphdr{
  clear:both;
  width:100%;
  text-align:center;
  font-size:4.5em;
  mix-blend-mode: soft-light;
  margin-bottom:40px;
  padding-top:70px;
}

.colHdr{
  font-size:2.5em;
  mix-blend-mode: soft-light;
  margin-bottom:20px;
}

.colDiv{
  border-right:2px solid black;
  
}
@media (max-width: 1430px) {
  #frokImg{
    margin-top:0px;

}
}
@media (max-width: 1020px) {

  .roadmaphdr{
font-size:3.5em;
  }
  .colHdr{
    font-size:2em;
  }
  .nomics1{
    display:none;
  }
  .roadmaphdr{
    text-align:left;
    margin-left:30px;
  }
  .infoCol{
    width:80%;
    margin: 0 auto;
    margin-bottom:50px;
    text-align:left;
    margin-left:30px;
  }

  .colDiv{
    border-right:none;
    border-bottom:2px solid black;
    padding-bottom:50px;
  }

#frokImg{
  margin: 0;
  max-width:100%;
  margin-top:-190px;
  z-index:8;
  float:left;
  position:relative;
}

.mintbox{
float:left;
margin-top:-70px;
width:90%;
z-index:99;
font-size:3em;
mix-blend-mode: soft-light;
margin-left:30px;
margin-bottom:40px;
}

w3m-button{
  right:20px;
}

.NavBar{
  top:-20px;
}

#logo{
margin-top:80px;
}

.mintbtn{

  clear: both;
  /* margin-top: 120px; */
  float: left;
  margin-left: -307px;
  margin-bottom: 20px;

    
}

}